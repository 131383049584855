<template>
  <div class="outer-wrapper">
    <!-- If Tink doesn't work we show the old way of entering account details manually -->
    <div
      v-if="tinkError"
      class="account"
    >
      <SingleChoice
        :alternatives="alternatives"
        information
        :disabled="!isFormValid"
        @show-information="() => (showDialog = true)"
        @click="goToNext"
      >
        <QuestionTextSection
          :heading="$t('tinkCommunicationError')"
          :body="$t('tinkErrorBody')"
        />
        <div>
          <div class="account__dropdown">
            <v-select
              v-model="chosenBank"
              :bg-color="buttonColor"
              :items="items"
              :label="$t('selectBank')"
              variant="solo"
              hide-details
              single-line
              menu-icon="fas fa-chevron-down"
              data-cy="bank"
              @update:model-value="setClearingNumberErrorState"
            >
              <template #selection="props">
                <span
                  v-bind="props"
                  class="account__dropdown--text"
                >{{ props.item.title }}</span>
              </template>
            </v-select>
          </div>
          <v-form
            ref="form"
            v-model="isFormValid"
            class="account-form"
            @submit.prevent="goToNext"
          >
            <p
              v-if="$vuetify.display.mdAndUp"
              class="account-form__label"
            >
              {{ $t("clearing_number") }}
            </p>
            <v-text-field
              v-model="editedClearingNumber"
              validate-on="blur"
              type="tel"
              variant="underlined"
              :error="clearingNumberErrorState"
              :rules="[rules.required, rules.number]"
              :label="$vuetify.display.mdAndUp ? '' : clearingNumberLabel"
              data-cy="clearing-value"
              name="clearingNum"
              class="account-form__value"
              @focus="clearingNumberErrorState = false"
              @blur="setClearingNumberErrorState"
              @update:model-value="validateForm"
            />

            <p
              v-if="$vuetify.display.mdAndUp"
              class="account-form__label"
            >
              {{ $t("accountNumber") }}
            </p>
            <v-text-field
              v-model="editedAccountNumber"
              validate-on="blur"
              type="tel"
              variant="underlined"
              :label="$vuetify.display.mdAndUp ? '' : accountNumberLabel"
              :rules="[rules.required, rules.number, rules.validAccountNumber]"
              data-cy="accountnumber-value"
              name="accountNumber"
              class="account-form__value"
              @focus="clearingNumberErrorState = false"
              @blur="setClearingNumberErrorState"
              @update:model-value="validateForm"
            />
            <input
              tabindex="-1"
              class="account-form__value--hidden"
              type="submit"
              value=""
            >
          </v-form>
          <SectionedSideDialog
            v-model="showDialog"
            :info-sections="infoSections"
            :title="$t('accountNumber')"
          />
        </div>
      </SingleChoice>
    </div>
    <div
      v-if="!tinkError"
      class="tink-verification"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <SingleChoice
          v-if="loadingAccountDetails"
          key="1"
          :alternatives="alternatives"
          disabled
          class="animated"
        >
          <QuestionTextSection
            :heading="$t('verifyingBankAccount')"
            :alternatives="alternatives"
            @click="buttonClick"
          />
          <LoadingSpinner />
        </SingleChoice>
        <SingleChoice
          v-else
          key="2"
          :alternatives="alternatives"
          :disabled="clearingNumberParsingError"
          class="animated"
          @click="buttonClick"
        >
          <QuestionTextSection
            :heading="$t('bankAccountRetrieved')"
            :body="$t('tinkAccountExplanation')"
            :alternatives="alternatives"
            @click="buttonClick"
          />
          <div class="tink-verification__wrapper">
            <div class="tink-verification__wrapper-safari-fix">
              <div class="tink-verification__grid">
                <p class="tink-verification__wrapper-label">
                  {{ $t("bank") }}
                </p>
                <p class="tink-verification__wrapper-value">
                  {{ bank }}
                </p>
                <p class="tink-verification__wrapper-label">
                  {{ $t("accountNumber") }}
                </p>
                <div class="tink-verification__account-wrapper">
                  <p class="tink-verification__wrapper-value">
                    {{ bankAccountName }}
                  </p>
                  <span class="tink-verification__wrapper-hinttext">
                    {{ fullBankAccountNumber }}
                  </span>
                </div>
              </div>
            </div>
            <p
              v-if="clearingNumberParsingError"
              class="tink-verification__wrapper-error"
            >
              {{ $t("create-portfolio.questions.account.tink-verification.clearingError") }}
            </p>
          </div>
        </SingleChoice>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Kontonummer from "kontonummer";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import Question from "@/views/signup/templates/question";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { SignupMutation } from "@/store/signup/mutations";
import { softBlue } from "@/styles/colors";
import type { InfoSectionMultiple } from "@/types";
import type { Bank } from "@/types/portfolio";
import type { BankOptions } from "@/types/signup";
import { banksWithTransactionAccounts } from "@/types/signup";
import type { TiedAgent } from "@/clients";
import SectionedSideDialog from "@/components/dialog/sectioned-side-dialog.vue";

interface VSelectItem {
  title: string;
  value: string;
}

export default defineComponent({
  components: {
    QuestionTextSection,
    SingleChoice,
    LoadingSpinner,
    SectionedSideDialog,
  },
  extends: Question,
  data: () => ({
    // All below are for when tink fails
    isFormValid: false,
    showDialog: false,
    softBlue,
    clearingNumberErrorState: false,
  }),
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    buttonColor(): string | undefined {
      return this.$store.state.signupStore.tiedAgent?.color ?? softBlue;
    },
    tinkError(): boolean {
      return this.$store.state.signupStore.tinkError;
    },
    alternatives(): { text: string; key: string }[] {
      return [
        {
          text: this.$t("next"),
          key: "next",
        },
      ];
    },
    loadingAccountDetails(): boolean {
      return this.$store.state.signupStore.loadingAccountDetails;
    },
    bank(): string {
      return this.$store.state.signupStore.bankName;
    },
    fullBankAccountNumber(): string {
      return this.$store.state.signupStore.fullBankAccountNumber;
    },
    bankAccountName(): string {
      return this.$store.state.signupStore.bankAccountName;
    },
    clearingNumberParsingError(): any {
      return this.$store.state.signupStore.clearingNumberParsingError;
    },
    // Below are all for when tink fails:
    chosenBank: {
      get(): string {
        return this.$store.state.signupStore.bankName;
      },
      set(value: Bank) {
        this.$store.commit(SignupMutation.setBank, value);
      },
    },
    items(): VSelectItem[] {
      return banksWithTransactionAccounts.map((x: BankOptions) => ({
        title: x,
        value: x,
        key: x,
      }));
    },
    rules(): any {
      return {
        required: (value: string) => !!value || this.$t("required"),
        number: (value: string) => {
          value = value ? value.replace(/\s/g, "") : "";
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("not_valid_number");
        },
        validAccountNumber: () => {
          const valid
            = this.bankFromAccountNumber
            && this.bankFromAccountNumber.valid
            && this.chosenBank === this.bankFromAccountNumber.bankName;
          return valid || this.$t("does_not_match_bank");
        },
      };
    },
    bankFromAccountNumber(): Bank | undefined {
      const strippedClearingNumber = this.removeWhiteSpace(this.editedClearingNumber);
      const strippedAccountNumber = this.removeWhiteSpace(this.editedAccountNumber);
      let bank: Kontonummer | undefined;
      try {
        bank = new Kontonummer(strippedClearingNumber, strippedAccountNumber, {
          mode: "lax",
        });
      } catch (error: any) {
        // Throws despite setting mode: lax if user hasn't finished typing yet. Just ignore this.
      }
      if (bank) {
        return {
          bankName: bank.bankName as string,
          accountNumber: bank.accountNumber,
          clearingNumber: bank.sortingCode,
          valid: bank.valid,
        };
      }
      return undefined;
    },
    bankName(): string | undefined {
      if (this.bankFromAccountNumber) {
        return this.bankFromAccountNumber.bankName;
      }
      return undefined;
    },
    clearingNumberLabel(): string {
      return this.$t("clearing_number");
    },
    accountNumberLabel(): string {
      return this.$t("accountNumber");
    },
    editedClearingNumber: {
      get(): string {
        return this.$store.state.signupStore.clearingNumber;
      },
      set(value: string) {
        this.$store.commit(SignupMutation.setClearingNumber, this.removeWhiteSpace(value));
      },
    },
    editedAccountNumber: {
      get(): string {
        return this.$store.state.signupStore.bankAccountNumber;
      },
      set(value: string) {
        this.$store.commit(SignupMutation.setBankAccountNumber, this.removeWhiteSpace(value));
      },
    },
    infoSections(): InfoSectionMultiple[] {
      return [
        {
          heading: this.$t("infoTransactionTitle"),
          subSections: [
            {
              texts: [this.$t("infoTransactionText")],
            },
          ],
        },
        {
          heading: this.$t("accountNumber"),
          subSections: [
            {
              texts: [this.$t("infoAccountNumberText1"), this.$t("infoAccountNumberText2")],
            },
          ],
        },
      ];
    },
  },
  watch: {
    chosenBank: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$refs.form && this.editedClearingNumber && this.editedAccountNumber) {
          await (this.$refs.form as any).validate();
        }
      },
    },
  },
  methods: {
    async buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    // Old methods when tink fails:
    removeWhiteSpace(value: string | undefined): string {
      if (!value) {
        return "";
      }
      return value.replace(/\s/g, "");
    },
    async goToNext() {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.$store.commit(
          SignupMutation.setFullBankAccountNumber,
          this.editedClearingNumber + this.editedAccountNumber,
        );
        this.$store.commit(SignupMutation.setPaymentMethod, this.$t("accountNumber"));
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    },
    async setClearingNumberErrorState() {
      if (this.$refs.form && this.editedClearingNumber && this.editedAccountNumber) {
        this.clearingNumberErrorState = !(await (this.$refs.form as any).validate()).valid;
      }
    },
    async validateForm(): Promise<boolean> {
      if (this.$refs.form && this.editedClearingNumber && this.editedAccountNumber) {
        return (await (this.$refs.form as any).validate()).valid;
      }
      return false;
    },
  },
});
</script>

<style
  lang="scss"
  scoped
>
.outer-wrapper {
  width: 100%;
  height: 100%;
}

.account {
  width: 100%;
  height: 100%;

  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 18.75rem;
    width: 100%;
    margin: 6vh auto 0 auto;

    &--text {
      color: white;
      font-weight: 600;
      font-size: 1rem;
    }

    &--icon {
      color: white;
    }
  }

  &__side-dialog {
    padding: 1rem;

    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }

    &--text {
      font-weight: 300;
    }
  }
}

.account-form {
  align-items: center;
  max-width: 31.25rem;
  margin: 1rem auto 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-rows: 5rem 5rem;

  :deep(.v-field__input) {
    font-size: 1.5rem;

    @include medium-up {
      font-size: 2.5rem;
      padding-top: 0.2rem !important;
    }
  }

  &__bank {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
  }

  @include medium-up {
    grid-template-columns: 1fr 10rem 10rem;

    :nth-child(1) {
      grid-row: 1;
    }

    // :nth-child(2) {
    //   grid-row: 1;
    // }
    :nth-child(3) {
      grid-row: 2;
    }

    :nth-child(4) {
      grid-row: 2;
      grid-column: 2 / -1;
    }

    :nth-child(5) {
      grid-row: 3;
    }

    :nth-child(6) {
      grid-row: 3;
      grid-column: 2 / -1;
    }
  }

  &__label {
    margin: 0;
    text-align: right;
    font-size: 1.25rem;
  }

  &__value {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    font-family: $heading-font-family;

    @include medium-up {
      font-size: 2.5rem;
    }

    &--hidden {
      display: none;
    }
  }

  :deep(.v-input .v-label) {
    font-weight: normal;
  }

  :deep(.v-input input) {
    color: $soft-blue;
    min-height: 3.125rem !important;
    padding: 0;
    // This is needed in order for the text not to clip on Safari.
    max-height: inherit !important;
  }

  :deep(.v-text-field__details) {
    font-weight: normal;
  }
}

.tink-verification {
  height: 100%;
  width: 100%;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  &__wrapper-safari-fix {
    // Fixes grid row height overflowing in safari (https://stackoverflow.com/a/63197330/2152511)
    display: grid;
    align-items: center;
    justify-items: center;
  }

  &__grid {
    max-width: 21.75rem;
    margin: 2vh auto 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.5rem;
  }

  &__wrapper-label {
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
  }

  &__wrapper-value {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    font-family: $heading-font-family;
    text-align: right;
  }

  &__wrapper-hinttext {
    font-weight: 300;
    font-size: 1rem;
    opacity: 0.63;
    text-align: right;
    margin: 0;
    white-space: nowrap;
  }

  &__wrapper-error {
    margin-top: 2rem;
    color: red;
  }

  &__account-wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
