<template>
  <!-- Don't show if all tasks have been completed, but we don't know if all tasks 
  have been completed until all data is loaded -->
  <div
    v-if="showGetStarted && !loadingData && !allCompleted"
    class="get-started"
  >
    <v-menu
      v-model="showCard"
      location="top"
      offset="16"
      :close-on-content-click="false"
    >
      <template #activator="{ props }">
        <common-button
          rounded
          class="get-started__button"
          v-bind="props"
          >{{ $t("components.get-started.getStarted") }}</common-button
        >
      </template>

      <v-card
        class="get-started__card"
        :color="softBlue"
      >
        <v-btn
          icon
          variant="flat"
          width="2.25rem"
          height="2.25rem"
          data-jest="button"
          style="
            font-size: 1.25rem;
            position: absolute;
            top: 0.15rem;
            right: 0.05rem;
            background-color: transparent;
          "
          @click="showCard = false"
        >
          <icon
            class="get-started__exit-icon"
            :icon="['fal', 'times']"
            data-jest="button-icon"
          />
        </v-btn>
        <div class="get-started__intro">
          <div style="font-weight: bold; font-size: 1.25rem">
            {{ $t("components.get-started.title") }}
          </div>
          <div style="text-wrap: balance; line-height: 1.2">
            {{ $t("components.get-started.body") }}
          </div>
        </div>
        <v-list
          density="compact"
          class="get-started__list"
        >
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :class="['get-started__list-item', { 'get-started__list-item--not-due': !item.due }]"
            dark
            :disabled="item.completed || !item.due"
            @click="!item.completed && item.handler()"
          >
            <template v-slot:prepend>
              <icon
                v-if="item.completed"
                color="white"
                class="get-started__icon"
                fixed-width
                :icon="['fas', 'check-circle']"
              />
              <icon
                v-else
                class="get-started__icon"
                fixed-width
                :icon="['fal', 'circle']"
              />
            </template>
            <v-list-item-title style="font-size: 1.125rem; font-weight: bold; line-height: 2">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider
          thickness="1px"
          length="68%"
          width="68%"
          color="white"
          style="opacity: 1"
        ></v-divider>
        <v-checkbox-btn
          v-model="showGetStartedSetting"
          :label="$t('components.get-started.dontShowAgain')"
          :true-value="false"
          :false-value="true"
          style="margin-bottom: 0.5rem"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { NavigationState } from "@/store/navigation/types";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { UserState } from "@/store/user/types";
import { PortfolioState } from "@/store/portfolio/types";
import { OverviewState } from "@/store/overview/types";
import { PortfolioSettings, TransactionEventType } from "@/clients";
import { softBlue } from "@/styles/colors";

interface Task {
  title: string;
  due: boolean;
  completed: boolean;
  handler?: Function;
}

export default defineComponent({
  components: {
    CommonButton
  },
  data(): any {
    return {
      selected: undefined,
      showCard: false,
      softBlue,
      showGetStartedSettingInitial:
        localStorage.getItem("SHOW_GET_STARTED") === "true" ||
        localStorage.getItem("SHOW_GET_STARTED") === null ||
        localStorage.getItem("SHOW_GET_STARTED") === undefined,
      showGetStartedSetting: true
    };
  },
  created() {
    window.addEventListener("beforeunload", () => {
      if (!this.showGetStartedSetting) {
        this.showGetStartedSettingInitial = false;
        localStorage.setItem("SHOW_GET_STARTED", "false");
      }
    });
  },
  computed: {
    showGetStarted(): boolean {
      const { showGetStarted } = this.$store.state.userStore as UserState;
      return showGetStarted && this.showGetStartedSettingInitial;
    },
    allCompleted(): boolean {
      return (this.items as Task[]).every((x) => x.completed);
    },
    loadingData(): boolean {
      // We won't load any data if we don't have any portfolios
      if (this.portfolioCount === 0) {
        return false;
      }
      return (
        this.loadingPerformance ||
        !this.portfolioSettings ||
        (this.$store.state.portfolioStore as PortfolioState).loadingEvents
      );
    },
    items(): Task[] {
      return [
        {
          title: this.$t("components.get-started.createUser"),
          due: true,
          completed: true
        },
        {
          title: this.$t("createPortfolio"),
          completed: this.portfolioCount > 0,
          due: true,
          handler: () => this.$router.push({ name: "create-portfolio" })
        },
        {
          title: this.$t("connectBankAccount"),
          completed: this.hasConnectedBankAccount,
          due: this.portfolioCount > 0,
          handler: this.openConnectBankAccount
        },
        {
          title: this.$t("components.get-started.makeDeposit"),
          completed: this.hasMadeDeposit,
          due: this.portfolioCount > 0,
          handler: this.openDeposit
        },
        {
          title: this.$t("components.get-started.startMonthlySaving"),
          completed: this.hasMonthlySaving,
          due: this.portfolioCount > 0,
          handler: this.goToPortfolioSettings
        }
      ];
    },
    portfolioCount(): number {
      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      return portfolios ? portfolios.length : 0;
    },
    hasConnectedBankAccount(): boolean {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio
      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios === null || portfolios === undefined || portfolios.length === 0) {
        return false;
      }

      if (
        portfolios.length === 1 &&
        portfolios[0].transferReceiverId !== null &&
        portfolios[0].transferReceiverId !== undefined
      ) {
        return true;
      }
      const { activePortfolioBase } = this.$store.state.portfolioStore as PortfolioState;
      const result =
        activePortfolioBase?.transferReceiverId !== null &&
        activePortfolioBase?.transferReceiverId !== undefined;
      return result;
    },
    hasMadeDeposit(): boolean {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio

      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios === null || portfolios === undefined || portfolios.length === 0) {
        return false;
      }
      const { activePortfolioBase } = this.$store.state.portfolioStore as PortfolioState;
      const portfolioToCheck = portfolios.length === 1 ? portfolios[0] : activePortfolioBase;
      if (!portfolioToCheck) {
        return false;
      }

      const performance = (this.$store.state.overviewStore as OverviewState).portfolioPerformances;
      const { transactionEvents } = this.$store.state.portfolioStore as PortfolioState;

      const hasCash =
        performance[portfolioToCheck.id] &&
        performance[portfolioToCheck.id].totalAbsolute?.some((x) => x.value && x.value > 0);
      const hasInitialDeposit = this.portfolioSettings.initialDeposit > 0;
      const hasOngoingDeposit =
        transactionEvents &&
        transactionEvents.some(
          (x) =>
            x.type === TransactionEventType.DepositOneTimeAutogiro ||
            x.type === TransactionEventType.DepositOneTimeFinshark
        );

      if (hasCash || hasInitialDeposit || hasOngoingDeposit) {
        return true;
      }

      return false;
    },
    hasMonthlySaving(): boolean {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio

      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios === null || portfolios === undefined || portfolios.length === 0) {
        return false;
      }
      const { activePortfolioBase } = this.$store.state.portfolioStore as PortfolioState;
      const portfolioToCheck = portfolios.length === 1 ? portfolios[0] : activePortfolioBase;

      if (
        portfolioToCheck &&
        portfolioToCheck.transferReceiverId &&
        this.portfolioSettings &&
        this.portfolioSettings.monthlySaving > 0
      ) {
        return true;
      }
      return false;
    },
    portfolioSettings(): PortfolioSettings | undefined {
      const { portfolioSettings } = this.$store.state.portfolioStore as PortfolioState;
      if (portfolioSettings) {
        return portfolioSettings;
      }
      return undefined;
    },
    loadingPerformance(): boolean {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio

      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios === null || portfolios === undefined || portfolios.length === 0) {
        return false;
      }

      const { activePortfolioBase } = this.$store.state.portfolioStore as PortfolioState;
      const portfolioToCheck = portfolios.length === 1 ? portfolios[0] : activePortfolioBase;

      const { performanceLoading } = this.$store.state.overviewStore as OverviewState;
      const loading =
        portfolioToCheck !== undefined &&
        portfolioToCheck !== null &&
        performanceLoading[portfolioToCheck.id];

      return loading;
    }
  },
  methods: {
    async openConnectBankAccount(): Promise<void> {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio

      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios && portfolios.length === 1) {
        await this.$router
          .push({ name: "portfolio", params: { id: portfolios[0].id } })
          .catch((_: any) => {});
        this.$store.commit(PortfolioMutation.setShowConnectBankSideDialog, true);
      } else {
        this.$store.commit(PortfolioMutation.setShowConnectBankSideDialog, true);
      }
    },
    async openDeposit(): Promise<void> {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio

      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (
        portfolios &&
        portfolios.length === 1 &&
        this.$router.currentRoute.value.name !== "portfolio"
      ) {
        await this.$router
          .push({ name: "portfolio", params: { id: portfolios[0].id } })
          .catch((_: any) => {});
        this.$store.commit(PortfolioMutation.setShowDepositDialog, true);
      } else {
        // We are already on the portfolio
        this.$store.commit(PortfolioMutation.setShowDepositDialog, true);
      }
    },
    goToPortfolioSettings(): void {
      // We assume that for more than one portfolio we have an active one
      // because this component is not shown on overview/profile when there is more than one portfolio

      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios && portfolios.length === 1) {
        this.$router.push({ name: "portfolio-settings", params: { id: portfolios[0].id } });
      } else {
        const { activePortfolioBase } = this.$store.state.portfolioStore as PortfolioState;
        if (activePortfolioBase && this.$router.currentRoute.value.name !== "portfolio-settings") {
          this.$router.push({ name: "portfolio-settings", params: { id: activePortfolioBase.id } });
        }
      }
    }
  },
  watch: {
    showCard(newVal) {
      if (!newVal && !this.showGetStartedSetting) {
        this.showGetStartedSettingInitial = false;
        localStorage.setItem("SHOW_GET_STARTED", "false");
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.get-started {
  position: fixed;
  left: 5rem;
  bottom: 1rem;
  z-index: 10;

  @include small-up {
    left: 6rem;
    bottom: 1.5rem;
  }

  &__card {
    background-color: $soft-blue;
    color: white;
    width: 18.75rem;
    height: 24.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__intro {
    padding: 1.1rem 1.1rem 0.5rem 1.1rem;
    text-align: center;
  }
  &__list {
    background-color: $soft-blue;
    color: white;
  }
  &__icon {
    font-size: 1.3rem;
    margin-right: 0.8rem;
  }
  &__list-item {
    &--not-due {
      opacity: 0.5;
    }
    &:hover {
      @include cursorHover {
        background: rgba($soft-blue, 0.9);
      }
    }
  }
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px;
  margin-left: 14px;
}
.theme--dark.v-list-item--disabled {
  color: unset;
}
.v-menu__content {
  // Fixes the blue background-color not quite filling out edges around the card
  // because v-card and v-menu have different border-radius
  border-radius: 0.5rem;
}
</style>
