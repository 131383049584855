<template>
  <div class="portfolio-summary">
    <Box justify="space-between" align="center">
      <div style="display: flex; flex-direction: row; align-items: center">
        <img
          class="portfolio-summary__icon"
          :src="getAssetUrl(isPension ? 'icons/pension-pyramid-black.svg' : 'icons/briefcase_filled.svg')"
        >
        <icon
          v-if="portfolio?.isClosing"
          class="portfolio-summary__closing-icon"
          :icon="['fa', 'trash-clock']"
        />
        <h2 class="portfolio-summary__heading">
          {{ portfolioName }}
        </h2>
      </div>
      <icon
        style="color: #c1c0c2"
        :icon="['fas', 'chevron-right']"
      />
    </Box>
    <Box justify="space-between" align="center">
      <div style="font-size: 1.5rem; font-weight: 500">
        {{ totalValueString }} <span style="font-size: 1.125rem">kr</span>
      </div>
      <PercentageDevelopment
        :performance="performance"
        :portfolio-id="portfolioId"
      />
    </Box>
    <LoadingSpinnerOverlay :model-value="performanceLoading" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { type PerformanceResponse, type PortfolioBase, PortfolioType } from "@/clients";
import Box from "@/components/box.vue";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import PercentageDevelopment from "@/components/percentage-development.vue";
import type { NavigationState } from "@/store/navigation/types";
import type { OverviewState } from "@/store/overview/types";
import type { PortfolioState } from "@/store/portfolio/types";
import { getAssetUrl } from "@/utils/assets";
import { formatTotalPortfolioValue } from "@/utils/development";

export default defineComponent({
  components: {
    Box,
    LoadingSpinnerOverlay,
    PercentageDevelopment,
  },
  props: {
    portfolioId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isPension(): boolean | undefined {
      return this.portfolio
        ? this.portfolio.portfolioType === PortfolioType.OccupationalPension
        : undefined;
    },
    performanceLoading(): boolean | undefined {
      const overviewState = this.$store.state.overviewStore as OverviewState;
      return overviewState.performanceLoading[this.portfolioId];
    },
    portfolio(): PortfolioBase | undefined {
      const navigationState = this.$store.state.navigationStore as NavigationState;
      return navigationState.portfolios?.find(({ id }) => id === this.portfolioId);
    },
    performance(): PerformanceResponse | undefined {
      if (!this.portfolio) {
        return undefined;
      }

      const overviewState = this.$store.state.overviewStore as OverviewState;
      return overviewState.portfolioPerformances[this.portfolio.id];
    },
    totalValueString(): string {
      const { isLoadingHoldingSummary } = this.$store.state.portfolioStore as PortfolioState;

      if (!this.performance || isLoadingHoldingSummary) {
        return "-";
      }
      return formatTotalPortfolioValue(this.performance);
    },
    portfolioName(): string {
      return this.portfolio?.name || "-";
    },
  },
  methods: {
    getAssetUrl,
  },
});
</script>

<style lang="scss" scoped>
.portfolio-summary {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  &__heading {
    font-size: 1.125rem;
    font-weight: 600;
  }

  &__closing-icon {
    color: $alert-red;
    margin-right: 0.5rem;
  }

  &__icon {
    color: $nearly-black;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
}
</style>
