<template>
  <v-btn
    size="large"
    :min-width="sizeLarge ? '11.75rem' : '8.125rem'"
    min-height="3rem"
    data-jest="button"
    data-cy="button"
    v-bind="$attrs"
    class="button"
    :variant="secondary && !colors ? 'text' : 'flat'"
    :class="[
      { 'button--secondary': secondary },
      { 'button--rounded': rounded },
    ]"
    :color="buttonColor"
    @click="buttonClick"
  >
    <slot />
    <!-- Enables forwarding slots to v-btn. See https://stackoverflow.com/a/72721695/2152511 -->
    <template
      v-for="(_, slot) in $slots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { type Colors, softBlue } from "@/styles/colors";
import type { SignupState } from "@/store/signup/types";

export default defineComponent({
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
    sizeLarge: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: undefined,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["click"],
  data: () => ({
    softBlue,
  }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    buttonColor(): string | undefined {
      if (this.colors) {
        if (this.secondary) {
          return this.colors.secondary;
        } else {
          return this.colors.accent;
        }
      } else {
        if (this.secondary) {
          return undefined;
        }
        return this.color ? this.color : softBlue;
      }
    },
  },
  methods: {
    buttonClick(event: Event) {
      // Need to pass on events when we use this for vuetify activator functions
      // such as v-menu
      this.$emit("click", event);
    },
  },
});
</script>

<style lang="scss" scoped>
.button {
  font-family: $heading-font-family;
  text-transform: none;
  font-size: 1.125rem;
  box-shadow: 0 0.1875rem 0.75rem #00000014;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: background-color 0.1s ease-in-out;
  letter-spacing: 0;

  &--rounded {
    border-radius: 1.5rem;
  }

  &:before {
    background: transparent;
    transition: all 0.1s ease-in-out;
  }

  &:hover:before {
    @include cursorHover {
      background: black;
    }
  }

  &.focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
  }

  &--secondary {
    color: rgba($nearly-black, 0.8);
  }
}
</style>
