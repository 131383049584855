<template>
  <div class="summary-confirm">
    <div
      v-if="preparingAgreements"
      class="summary-confirm__prepare-agreements"
    >
      <p>{{ $t("preparingDocuments") }}</p>
      <loading-spinner />
    </div>
    <div
      v-else
      class="summary-confirm__wrapper"
    >
      <div class="summary-confirm__agreements">
        <div style="font-weight: 600; margin-left: 1rem">
          {{ $t("components.summary-confirm.body1") }}
        </div>
        <ul style="font-weight: 300; margin-left: 2rem">
          <li
            v-for="bullet in bullets"
            :key="bullet"
          >
            {{ bullet }}
          </li>
        </ul>
        <div
          class="summary-confirm__link"
          @click="$emit('agreements')"
        >
          {{ $t("agreements") }}
          <icon
            fixed-width
            :icon="['fal', 'angle-right']"
          />
        </div>
      </div>
      <div class="summary-confirm__checkbox-wrapper">
        <v-form>
          <v-checkbox-btn
            class="summary-confirm__checkbox"
            v-model="confirmedInfo"
            hide-details
            color="#4F637B"
            data-cy="checkbox"
          />
        </v-form>
        <p>
          {{ $t("checkboxLabel") }}
        </p>
      </div>
    </div>
    <slot> </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";

export default defineComponent({
  emits: ["agreements", "update:model-value"],
  components: {
    LoadingSpinner
  },
  props: {
    bullets: {
      type: Array as () => Array<string>,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    preparingAgreements: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    confirmedInfo: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    }
  }
});
</script>

<style lang="scss">
.summary-confirm {
  height: 100%;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__prepare-agreements {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
  &__agreements {
    margin-top: 1.5rem;
    text-align: left;
  }
  &__link {
    cursor: pointer;
    text-decoration: underline;
    margin-top: 1rem;
    text-align: center;
  }
  &__checkbox-wrapper {
    margin-top: 1rem;
    max-width: 21.25rem;
    font-weight: 300;
    justify-self: center;
    display: flex;
    align-items: flex-start;
    font-size: 1rem;

    @include medium-up {
      max-width: 31rem;
      margin-top: 4rem;
    }
  }
  &__checkbox-text {
    margin-top: 0.25rem;
    font-weight: 300;
  }
  :deep(.multiple-choice__alternative-text) {
    font-size: 0.95rem !important;
  }
  @include small-down {
    :deep(.multiple-choice__alternative-text) {
      font-size: 0.875rem !important;
    }
  }
}
</style>
