<template>
  <div class="choose-amount">
    <transition
      name="fade"
      mode="out-in"
    >
      <single-choice-template
        v-if="!showChangeFocus"
        key="1"
        :alternatives="alternatives"
        :information="true"
        :footer-message="$t('monthlySavingsFooter')"
        @click="goToNext"
        @showInformation="() => (showDialog = true)"
      >
        <question-text-section
          :heading="$t('create-portfolio.questions.account.monthly-savings.heading')"
          :body="$t('montlySavingsBody2')"
          progress
        />
        <v-form
          ref="form"
          class="choose-amount__form"
          @submit.prevent="goToNext"
        >
          <v-text-field
            v-model="displayMonthlySaving"
            type="tel"
            variant="underlined"
            :rules="[rules.number, rules.leadingZeros, rules.validMonthlySaving]"
            :label="monthlySavingsAmountLabel"
            :suffix="currencyPerMonthLabel"
            data-cy="monthly-amount-value"
            :placeholder="$t('sum')"
            class="choose-amount__form-value"
            validate-on="blur"
            persistent-placeholder
          />

          <v-slide-y-transition>
            <p
              v-if="tooHighMonthlySaving"
              class="choose-amount__form--custom-error"
            >
              {{ $t("tooHighMonthlySaving") }}
            </p>
          </v-slide-y-transition>
          <input
            tabindex="-1"
            class="choose-amount__form--hidden"
            type="submit"
            value=""
          />
        </v-form>

        <side-dialog
          v-model="showDialog"
          :title="$t('howToSave')"
        >
          <div class="choose-amount__side-dialog">
            <div
              v-for="(section, i) in informationAlternatives"
              :key="i"
            >
              <p class="choose-amount__side-dialog--heading">
                {{ section.title }}
              </p>
              <p
                v-for="(paragraph, index) in section.paragraph"
                :key="index"
                class="choose-amount__side-dialog--text"
              >
                {{ paragraph.information }}
              </p>
            </div>
          </div>
        </side-dialog>
      </single-choice-template>

      <single-choice-template
        v-else-if="showChangeFocus"
        key="2"
        :alternatives="focusAlternatives"
        @click="selectFocus"
      >
        <information-text-section :heading="$t('changeFocusHeading')" />
        <p class="choose-amount__change-focus-body">
          {{ $t("changeFocusBody1") }}
        </p>
        <p class="choose-amount__change-focus-body">
          {{ $t("changeFocusBody2") }}
        </p>
        <p class="choose-amount__change-focus-body">
          {{ $t("changeFocusBody3") }}
        </p>
      </single-choice-template>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { Market } from "@/clients";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SignupState } from "@/store/signup/types";
import { SingleChoice } from "../../../../types/signup";

type Paragraph = {
  information: string;
};
type InformationAlternative = {
  title: string;
  paragraph: Array<Paragraph>;
};

export default defineComponent({
  components: {
    QuestionTextSection,
    InformationTextSection,
    SingleChoiceTemplate,
    SideDialog
  },
  extends: Question,
  data() {
    return {
      showDialog: false,
      showChangeFocus: false,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*)$/;
          return pattern.test(value) || this.$t("notValidDeposit");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidDeposit");
        },
        validMonthlySaving: (stringValue: string) => {
          stringValue = stringValue.replace(/\s/g, "");
          const value = parseInt(stringValue, 10);
          const valid = value === 0 || value >= 500;
          return valid || this.$t("notValidMonthlySaving");
        },
        tooHighMonthlySaving: (stringValue: string) => {
          stringValue = stringValue.replace(/\s/g, "");
          const value = parseInt(stringValue, 10);
          const valid = value < this.$store.state.signupStore.monthlyIncome;
          return valid || this.$t("tooHighMonthlySaving");
        }
      }
    };
  },
  computed: {
    marketFocus(): Market {
      return this.$store.state.signupStore.marketFocus;
    },
    alternatives(): Array<SingleChoice> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    informationAlternatives(): InformationAlternative[] {
      return [
        {
          title: this.$t("monthlySaving"),
          paragraph: [
            { information: this.$t("monthlySavingsAmountInformation1") },
            { information: this.$t("monthlySavingsAmountInformation2") }
          ]
        }
      ];
    },
    currencyPerMonthLabel(): string {
      return this.$t("currencyPerMonth");
    },
    monthlySavingsAmountLabel(): string {
      return this.$t("monthlySaving");
    },
    displayMonthlySaving: {
      get(): string {
        if (this.monthlySavingsAmount !== undefined && this.monthlySavingsAmount !== null) {
          return this.monthlySavingsAmount.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.monthlySavingsAmount = parseInt(value, 10);
      }
    },
    monthlySavingsAmount: {
      get(): number | undefined {
        return this.$store.state.signupStore.monthlySavingsAmount;
      },
      set(value: number) {
        let submittedValue = 0;
        if (value) {
          submittedValue = value;
        }
        this.$store.commit(SignupMutation.setMonthlySavingsAmount, submittedValue);
      }
    },
    focusAlternatives(): Array<SingleChoice> {
      return [
        {
          text: this.$t("no"),
          key: "no",
          secondary: true
        },
        {
          text: this.$t("yesChange"),
          key: "yesChange"
        }
      ];
    },
    tooHighMonthlySaving(): boolean {
      // This is separate from Vuetify's validation rules since fulfilling this rule is not
      // required to move on. Validating only specific rules is not possible currently, but
      // seems to be planned for Vuetify 3 (https://github.com/vuetifyjs/vuetify/issues/8698)
      const stringValue = this.displayMonthlySaving.replace(/\s/g, "");
      const value = parseInt(stringValue, 10);
      const invalid = value > this.$store.state.signupStore.monthlyIncome;
      return invalid;
    }
  },
  methods: {
    removeWhiteSpace(value: string) {
      return value.replace(/\s/g, "");
    },
    async goToNext() {
      const valid = await (this.$refs.form as any).validate();
      if (valid.valid) {
        const { firstDeposit } = this.$store.state.signupStore as SignupState;
        if (
          (this.marketFocus === Market.Us || this.marketFocus === Market.Europe) &&
          this.monthlySavingsAmount !== undefined &&
          this.monthlySavingsAmount <= 1000 &&
          (firstDeposit === undefined || firstDeposit < 20000)
        ) {
          this.showChangeFocus = true;
        } else {
          this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
        }
      }
    },
    selectFocus(key: string): void {
      if (key === "yesChange") {
        this.$store.commit(SignupMutation.setMarketFocus, Market.Sweden);
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.choose-amount {
  width: 100%;
  height: 100%;
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
  &__change-focus-body {
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include medium-up {
      max-width: 44.5rem;
    }
    &:first-of-type {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__form-label {
    margin: 0;
    text-align: right;
    font-size: 1.25rem;
    &--align-left {
      text-align: left;
      margin-bottom: 0px;
      font-size: 1.25rem;
    }
  }
  &__form-value {
    width: 100%;
    max-width: 21rem;
    height: auto;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    font-family: $heading-font-family;
    @include medium-up {
      font-size: 2.5rem;
    }
  }
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    &--hidden {
      display: none;
    }
    :deep(.v-input input) {
      color: $soft-blue;
      min-height: 3.125rem !important;
      // padding: 0;
      // This is needed in order for the text not to clip on Safari.
      max-height: inherit !important;
      @include medium-up {
        text-align: center;
      }
    }
    :deep(.v-field__input) {
      font-size: 1.5rem;
      @include medium-up {
        font-size: 2.5rem;
        padding-top: 0.2rem !important;
      }
    }
    &--custom-error {
      grid-column: 1;
      color: red;
      @include medium-up {
        grid-column: 2;
      }
    }
    :deep(.v-input .v-label) {
      font-weight: normal;
    }
    :deep(.v-text-field__suffix) {
      opacity: 0.63;
      font-weight: normal;
      font-size: 1rem;
      @include medium-up {
        padding-top: 1.5rem !important;
      }
    }
    :deep(.v-text-field__details) {
      font-weight: normal;
    }
  }
}
</style>
