<template>
  <single-choice
    ref="singleChoice"
    :alternatives="[
      {
        text: $t('next'),
        key: 'next'
      }
    ]"
    data-jest="single-choice"
    @click="buttonClick"
  >
    <question-text-section
      :heading="$t('signup.questions.contact.heading')"
      :body="$t('signup.questions.contact.body')"
    />
    <div class="contact">
      <v-form
        ref="form"
        @submit.prevent="buttonClick"
      >
        <v-text-field
          v-model="email"
          :label="$t('emailRequired')"
          :rules="[rules.required, rules.email]"
          type="email"
          variant="underlined"
          data-cy="email-input"
          validate-on="blur"
        />
        <phone-number-input
          :initial-phone-number="phoneNumber"
          @update:model-value="(val) => (phoneNumber = val)"
        />
        <input
          tabindex="-1"
          class="contact__input--hidden"
          type="submit"
          value=""
        />
        <affiliate-code-input
          ref="affiliateCodeInput"
          v-model="affiliateCode"
          :is-natural-person="true"
        />
      </v-form>
      <div class="contact__personal-record-wrapper">
        <v-form ref="privacyForm">
          <v-checkbox-btn
            style="font-size: 1rem"
            :model-value="acceptedPersonalDataPolicy"
            color="#4F637B"
            data-cy="privacycheckbox"
            @update:model-value="(value: boolean) => (acceptedPersonalDataPolicy = value)"
          />
        </v-form>
        <p>
          {{ $t("personalRecordPolicy")
          }}<a
            href="https://www.sigmastocks.com/sigmastocks/integritetspolicy/"
            class="contact__personal-record-text"
            target="_blank"
            >{{ $t("personalRecordPolicyLinkPart") }}</a
          >.
        </p>
      </div>
    </div>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import PhoneNumberInput from "@/components/phone-number-input.vue";
import { SIGNUP_AFFILIATE_REFFERAL_CODE, SIGNUP_AFFILIATE_REFFERAL_NAME } from "@/config/general";
import { SignupState } from "@/store/signup/types";
import AffiliateCodeInput from "@/components/affiliate-code-input.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import { SignupAction } from "@/store/signup/actions";
import { LogLevel } from "@/clients";
import log from "@/clients/log";
import { trackEvent, trackEventOnlyGa4 } from "@/clients/segment";

const logger = "contact.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    SingleChoice,
    PhoneNumberInput,
    AffiliateCodeInput
  },
  extends: Question,
  data() {
    return {
      rules: {
        email: (value: string) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("invalidEmail");
        },
        required: (value: string) => !!value || this.$t("required")
      },
      codeErrorMessage: undefined as string | undefined
    };
  },
  computed: {
    email: {
      get(): string {
        return this.$store.state.signupStore.contactDetails.email;
      },
      set(value: string): void {
        this.$store.commit(SignupMutation.setEmail, value);
      }
    },
    phoneNumber: {
      get(): string {
        return this.$store.state.signupStore.contactDetails.phoneNumber;
      },
      set(value: string): void {
        this.$store.commit(SignupMutation.setPhoneNumber, value);
      }
    },
    acceptedPersonalDataPolicy: {
      get(): boolean {
        return this.$store.state.signupStore.acceptedPersonalDataPolicy;
      },
      set(value: boolean): void {
        this.$store.commit(SignupMutation.setAcceptedPersonalDataPolicy, value);
      }
    },
    affiliateCode: {
      get(): string | undefined {
        return (this.$store.state.signupStore as SignupState).affiliateReferralCode;
      },
      set(value: string): void {
        this.$store.commit(SignupMutation.setAffiliateReferralCode, value);
      }
    },
    affiliateReferralName(): string | undefined {
      return this.$store.state.signupStore.affiliateReferralName;
    }
  },
  methods: {
    async buttonClick() {
      const valid = await (this.$refs.form as any).validate();
      const valid2 = (await (this.$refs.affiliateCodeInput as any)).validate();
      if (valid.valid && valid2) {
        this.$store.dispatch(SignupAction.createLeadUser);
        log(logger, "ad_submitted_email", LogLevel.Information);
        trackEvent("submitted_email");
        trackEventOnlyGa4("conversion", { send_to: "AW-966604819/poB3CJbn6NECEJPw9MwD" });

        if (!this.affiliateCode) {
          this.$store.commit(SignupMutation.setAffiliateReferralName, undefined);
          sessionStorage.removeItem(SIGNUP_AFFILIATE_REFFERAL_NAME);
          sessionStorage.removeItem(SIGNUP_AFFILIATE_REFFERAL_CODE);
        }
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.contact {
  margin: 0 auto;
  @include medium-up {
    max-width: 31rem;
  }
  &__input {
    &--hidden {
      display: none;
    }
  }
  &__personal-record-wrapper {
    max-width: 21.25rem;
    justify-self: center;
    display: flex;
    align-items: flex-start;
    opacity: 0.5;
    font-size: 0.75rem;

    @include medium-up {
      max-width: 31rem;
    }
  }
  &__personal-record-text {
    margin-top: 0.25rem;
    font-weight: 300;
  }
}
:deep(.v-field__field) {
  input {
    font-weight: 500;
    @include medium-up {
      font-size: 1.25rem;
    }
  }
  label {
    @include medium-up {
      font-size: 1.25rem;
    }
  }
}
:deep(.v-autocomplete) {
  @include medium-up {
    font-size: 1.25rem;
  }
}
</style>
