export const aqua: string = "#34b1a7";
export const deepBlue: string = "#13263c";
export const dustyGreen: string = "#9acfc3";
export const nearlyBlack: string = "#2b2b2b";
export const softBlue: string = "#8aacb6";
export const softBlueDarker: string = "#7a98a1";
export const grayishBlue: string = "#304762";
export const softOrange: string = "#d0762e";
export const alertRed: string = "#a51717";
export const deepRed: string = "#a51717";
export const white: string = "#fff";
export const whisper: string = "#eeeeee";
export const frenchGrey: string = "#c1c0c2";
export const lightGreen: string = "#9ACFC3";
export const brandyPunch = "#D98F39";
export const amethyst = "#C33EC4";

export const sigmastocks: Colors = {
  text: nearlyBlack,
  accent: softBlue,
  secondary: white,
  background: "#fafafa",
  error: "#ff0000",
};

export const evida: Colors = {
  text: "#FFFFFF",
  accent: "#F77958",
  secondary: "#FFFFFF",
  background: "#141c48",
  error: "#ff0000",
};

export interface Colors {
  text: string;
  accent: string;
  secondary: string;
  background: string;
  error: string;
}
