<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="20000"
    color="white"
  >
    <div>
      <p class="prompt__text">
        {{ $t("components.ios-install-pwa.body1") }}
        <img
          class="prompt__icon"
          width="15"
          :src="getAssetUrl('icons/arrow-square-up-edited.svg')"
        >
        {{ $t("components.ios-install-pwa.body2") }}
      </p>
    </div>
    <v-btn
      class="prompt__button"
      dark
      color="black"
      variant="text"
      @click="close"
    >
      {{ $t("components.ios-install-pwa.notNow") }}
    </v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PWA_PROMPT_IGNORED_AT_KEY } from "@/config/general";
import { isSafari } from "@/clients/bowser";
import { getAssetUrl } from "@/utils/assets";

export default defineComponent({
  data: () => ({
    snackbar: false,
  }),
  created() {
    const isHandheldIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    const isSupported = isHandheldIos && isSafari() && (window.navigator as any).standalone === false;

    if (!isSupported) {
      return;
    }

    const lastIgnoredTimestamp = Number.parseInt(localStorage.getItem(PWA_PROMPT_IGNORED_AT_KEY) ?? "0");

    if (!Number.isInteger(lastIgnoredTimestamp)) {
      // Enable prompt because first time or faulty timestamp
      this.snackbar = true;
    }

    const diffInTime = Date.now() - lastIgnoredTimestamp;
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    // Only prompt once every two weeks
    if (diffInDays >= 14) {
      this.snackbar = true;
    }
  },

  methods: {
    close() {
      this.snackbar = false;

      // Store the current time when the prompt is ignored
      localStorage.setItem(PWA_PROMPT_IGNORED_AT_KEY, JSON.stringify(Date.now()));
    },
    getAssetUrl,
  },
});
</script>

<style lang="scss" scoped>
.prompt {
  &__text {
    color: $soft-blue;
    font-family: $heading-font-family;
    margin-bottom: 0.225rem !important;
    line-height: 1.5;
  }
  &__icon {
    margin: 0 0.25rem;
    vertical-align: bottom;
  }
  &__button {
    font-family: $heading-font-family;
    text-transform: none;
    padding: 0 !important;
  }
}
</style>
