<template>
  <single-choice>
    <div>
      <question-text-section
        :heading="$t('signup.questions.register.heading')"
        :body="body"
      />
      <div v-if="registrationState === RegistrationState.FAILED">
        <div class="login__error-icon">
          <icon
            v-if="registerUserError === RegisterUserError.USER_ALREADY_EXISTS"
            :icon="['fal', 'grin-beam']"
          />
          <span v-else-if="registerUserError === RegisterUserError.BELOW_AGE_18"> 18+ </span>
          <icon
            v-else
            :icon="['far', 'frown-open']"
          />
        </div>
        <div class="login__error-header">
          {{ failedHeader }}
        </div>
        <div class="login__error-body">
          {{ failedBody }}
        </div>
      </div>
    </div>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { softBlue } from "@/styles/colors";
import { LogLevel, RegisterUserError, RegisterUserErrorResponse } from "@/clients";
import { StartMode } from "@/store/bankid/types";
import CommonButton from "@/components/button.vue";
import { UserAction } from "@/store/user/action-definitions";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import BankIdAuthInput from "@/components/bank-id-auth-input.vue";
import { SignupAction } from "@/store/signup/actions";
import log from "@/clients/log";
import { trackEvent, trackEventGtm, trackEventOnlyGa4 } from "@/clients/segment";
import { SignupState } from "@/store/signup/types";

enum RegistrationState {
  PENDING,
  COMPLETED,
  FAILED
}

const logger = "bankid.vue";

export default defineComponent({
  components: {
    SingleChoice,
    QuestionTextSection,

    CommonButton,
    BankIdAuthInput
  },
  extends: Question,
  data() {
    return {
      softBlue,
      personalIdentityNumber: "",
      authFailure: null as string | null,
      StartMode,
      RegistrationState,
      registrationState: RegistrationState.PENDING,
      RegisterUserError,
      registerUserError: undefined as RegisterUserError | undefined
    };
  },
  computed: {
    failedBody() {
      switch (this.registerUserError) {
        case RegisterUserError.USER_ALREADY_EXISTS:
          return this.$t("registrationConflict");
        case RegisterUserError.BELOW_AGE_18:
          return this.$t("registrationBelowAge18");
        default:
          return this.$t("registrationFailed");
      }
    },
    failedHeader() {
      switch (this.registerUserError) {
        case RegisterUserError.USER_ALREADY_EXISTS:
          return this.$t("conflictHeader");
        case RegisterUserError.BELOW_AGE_18:
          return this.$t("cannotBecomeCustomer");
        default:
          return this.$t("signCompletionHeaderFailed");
      }
    },
    body(): string {
      if (this.registrationState === RegistrationState.PENDING) {
        return this.$t("registering");
      }
      return "";
    },
    isBankIdDev(): boolean {
      return import.meta.env.VITE_ENV !== "production";
    }
  },
  async created() {
    this.registrationState = RegistrationState.PENDING;

    try {
      await this.$store.dispatch(SignupAction.registerUserNew);
      trackEventOnlyGa4("created_account");

      if (this.$store.state.signupStore.referralCode) {
        trackEvent("referral_completed");
      }
      if (
        this.$store.state.signupStore.affiliateReferralName ||
        this.$store.state.signupStore.affiliateReferralCode
      ) {
        trackEvent("affiliate_referral_completed", {
          affiliateName: this.$store.state.signupStore.affiliateReferralName,
          affiliateCode: this.$store.state.signupStore.affiliateReferralCode
        });
      }
      trackEventGtm("signup_flow_simple_complete", {
        affiliate_code: (this.$store.state as SignupState).affiliateReferralCode
      });

      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    } catch (error: any) {
      this.registrationState = RegistrationState.FAILED;
      if (error instanceof RegisterUserErrorResponse) {
        this.registerUserError = error.registerUserError;
      }

      if (this.registrationState === RegistrationState.FAILED) {
        this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("registrationSnackbar"));
        log(
          logger,
          `Unexpected error in registration. Error message=(${error.message})`,
          LogLevel.Error
        );
        throw error;
      }
    }
  },
  methods: {
    authCancel() {
      this.$store.dispatch(SignupNavigationAction.goToPreviousStep, this.$router);
    },
    authFailed() {
      this.$store.dispatch(SignupNavigationAction.goToPreviousStep, this.$router);
      this.authFailure = this.$t("authFailed");
    }
  }
});
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 2.5625rem;
  align-items: center;
  text-align: center;
  height: 100%;

  @include small-up {
    padding-top: 5rem;
  }
  &__button {
    margin-bottom: 0.75rem;
  }
  &__loading {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__logo-container {
    width: 100%;
    text-align: center;
    height: 4.0625rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__text {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba($nearly-black, 0.8);
    padding-bottom: 1.5rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    padding-top: 3.25rem;
    justify-content: center;
    text-align: center;

    @include small-up {
      width: 18rem;
    }
    .v-input {
      font-size: 1.2rem;
      border-radius: 0.6rem;
      :deep(input) {
        text-align: center;
      }
    }
  }
  &__checkbox {
    &--text {
      color: $nearly-black;
    }
  }
  &__actions-container {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  &__signup-button {
    text-transform: none !important;
    color: $nearly-black;
    font-weight: 300;
    font-size: 1rem;
    font-family: $body-font-family;
    padding-top: 0.5rem;
  }
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 100%;
  }
  &__link-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    @include small-up {
      padding-bottom: 8rem;
    }
  }
  &__create-account-text {
    margin: auto;
    max-width: 18rem;
  }
  &__link {
    text-decoration: underline;
  }
  &__error {
    color: red;
    text-align: center;
    font-size: 0.9rem;
    margin-top: -0.3rem;
  }
  &__noaccount {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    &--header {
      font-weight: bold;
      font-size: 1.125rem;
      @include medium-up {
        font-size: 1.375rem;
      }
    }
    &--body {
      width: 14rem;
      text-align: center;
      font-size: 1rem;
      @include medium-up {
        width: 24rem;
        font-size: 1.125rem;
      }
    }
  }
  &__error-icon {
    padding-top: 2rem;
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
  }
  &__error-header {
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
  }
  &__error-body {
    text-align: center;
  }
}
</style>
