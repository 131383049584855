<template>
  <div
    v-if="showBanners"
    class="info-banner"
  >
    <v-card
      v-for="(banner, i) in banners"
      :key="i"
      class="info-banner__card"
      :class="
        banner.bannerType === bannerType.Problem || banner.bannerType === bannerType.ProblemHolding
          ? 'banner-type-problem'
          : 'banner-type-info'
      "
    >
      <div class="info-banner__icon-wrapper">
        <icon
          fixed-width
          :icon="[
            'fal',
            banner.bannerType === bannerType.Problem ? 'exclamation-square' : 'info-circle'
          ]"
          class="info-banner__icon"
        />
      </div>
      {{ banner.text }}
      <div class="info-banner__button">
        <v-btn
          style="background-color: transparent"
          icon
          variant="flat"
          width="3.25rem"
          height="3.25rem"
          data-jest="button"
          @click="() => hideBanner(banner.id)"
        >
          <icon
            class="info-banner__button-icon"
            :icon="['fal', 'times']"
            data-jest="button-icon"
          />
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  AutogiroStatus,
  Banner,
  BannerType,
  HoldingSummary,
  PortfolioBase,
  TransactionEvent,
  TransactionEventType
} from "@/clients";
import { HIDE_BANNER_KEY } from "@/config/general";
import { UserMutation } from "@/store/user/mutations";

enum UserBanner {
  AutogiroPending = -1,
  AutogiroMissing = -2,
  AutogiroRejected = -3,
  InvestmentPending = -4
}

export default defineComponent({
  components: {},
  data() {
    return { bannerType: BannerType, hiddenBanners: [] as number[] };
  },
  computed: {
    banners(): Banner[] | undefined {
      const infoBanners = this.$store.state.userStore.infoBanners as Banner[] | undefined;
      if (!infoBanners) {
        return undefined;
      }
      const noDismissedBanners = infoBanners.filter((i) => this.hiddenBanners.indexOf(i.id) === -1);

      const filteredBanners = noDismissedBanners.filter((b) => {
        if (b.bannerType === this.bannerType.Info || b.bannerType === this.bannerType.Problem) {
          return true;
        }
        if (
          b.isins &&
          this.withdrawalsAndHoldings &&
          this.withdrawalsAndHoldings.holdingSummaries?.holdings
        ) {
          return this.withdrawalsAndHoldings.holdingSummaries?.holdings?.find(
            (h) => (b.isins as string).split(",").indexOf(h.isin as string) > -1
          );
        }
        // If holdings isnt loaded, then hide the banner
        return false;
      });

      return filteredBanners;
    },
    showBanners(): boolean {
      return this.banners !== undefined && this.banners.length > 0;
    },
    portfolios(): PortfolioBase[] | undefined {
      return this.$store.state.navigationStore.portfolios;
    },
    withdrawalsAndHoldings(): {
      holdingSummaries: HoldingSummary | undefined;
      events: TransactionEvent[] | undefined;
    } {
      const { currentHoldingSummary } = this.$store.getters;
      return {
        holdingSummaries: currentHoldingSummary,
        events: this.$store.state.portfolioStore.transactionEvents
      };
    }
  },

  watch: {
    portfolios: {
      immediate: true,
      deep: false,
      handler(portfolios: PortfolioBase[] | undefined) {
        if (portfolios && portfolios.length > 0) {
          portfolios.forEach((p) => {
            if (
              p.autogiroStatus === AutogiroStatus.Created ||
              p.autogiroStatus === AutogiroStatus.Pending
            ) {
              this.addComputedBanner(
                UserBanner.AutogiroPending,
                this.$t("components.info-banner.autogiroPending"),
                BannerType.Info
              );
            } else if (p.autogiroStatus === AutogiroStatus.Missing) {
              this.addComputedBanner(
                UserBanner.AutogiroMissing,
                this.$t("components.info-banner.autogiroMissing"),
                BannerType.Problem
              );
            } else if (p.autogiroStatus === AutogiroStatus.Rejected) {
              this.addComputedBanner(
                UserBanner.AutogiroRejected,
                this.$t("components.info-banner.autogiroRejected"),
                BannerType.Problem
              );
            }
          });
        }
      }
    },
    withdrawalsAndHoldings: {
      immediate: true,
      deep: true,
      handler({
        holdingSummaries,
        events
      }: {
        holdingSummaries: HoldingSummary | undefined;
        events: TransactionEvent[] | undefined;
      }) {
        if (
          holdingSummaries?.holdings !== undefined &&
          holdingSummaries?.cash !== undefined &&
          events !== undefined
        ) {
          if (
            holdingSummaries.holdings.length === 0 &&
            holdingSummaries.cash.amount > 500 &&
            events.findIndex((e) => e.type === TransactionEventType.WithdrawalAutogiro) === -1
          ) {
            this.addComputedBanner(
              UserBanner.InvestmentPending,
              this.$t("components.info-banner.investmentPending"),
              BannerType.Info
            );
          }
        }
      }
    }
  },
  created() {
    const hiddenBannersString = sessionStorage.getItem(HIDE_BANNER_KEY);
    if (hiddenBannersString) {
      this.hiddenBanners = JSON.parse(hiddenBannersString) as number[];
    }
  },
  methods: {
    hideBanner(id: number): void {
      this.hiddenBanners.push(id);
      sessionStorage.setItem(HIDE_BANNER_KEY, JSON.stringify(this.hiddenBanners));
    },
    addComputedBanner(id: number, text: string, bannerType: BannerType) {
      this.$store.commit(
        UserMutation.appendBanner,
        new Banner({
          id,
          bannerType,
          createdAt: new Date(),
          text,
          isins: undefined
        })
      );
    }
  }
});
</script>

<style lang="scss" scoped>
.banner-type-problem {
  background: rgba($soft-red, 0.5);
}
.banner-type-info {
  background: rgba($soft-blue, 0.5);
}
.info-banner {
  &__card {
    display: flex;
    flex-direction: row;
    min-height: 3.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
    @include small-down {
      border-radius: 0px !important;
    }
  }
  &__icon-wrapper {
    font-size: 1.5rem;
    margin-right: 1.6875rem;
    margin-left: 1.5625rem;
  }
  &__button {
    width: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.1rem;
    margin-left: auto;
    @include small-up {
      width: 4.2rem;
    }
  }
  &__button-icon {
    font-size: 1.25rem;
    color: $nearly-black;
    @include medium-up {
      font-size: 1.5rem;
    }
  }
}
</style>
