<template>
  <div
    :class="['single-choice', { 'single-choice--top-padding': !noTopPadding }]"
    :style="
      $vuetify.display.mdAndUp && maxHeight !== undefined
        ? { maxHeight: `${maxHeight} !important` }
        : {}
    "
  >
    <div class="single-choice__information-button-wrapper">
      <img
        v-if="informationArrow"
        :src="require('@/assets/images/signup/bent-arrow.svg')"
        class="single-choice__information-button-arrow"
      />
      <v-btn
        v-if="information"
        class="single-choice__information-button single-choice__information-button--absolute"
        :color="primaryColor"
        height="3rem"
        width="3rem"
        :class="{ 'single-choice__information-button--margin-bottom': twoColumns }"
        @click="showInformation()"
      >
        <icon
          :color="primaryColor"
          :icon="['fas', 'info']"
          class="single-choice__information-button-icon"
        />
      </v-btn>
    </div>

    <div
      :class="[
        'single-choice__slot-wrapper',
        { 'single-choice__slot-wrapper--overflow': allowContentOverflow }
      ]"
    >
      <slot />
    </div>
    <div class="single-choice__footer">
      <p
        class="single-choice__error-message"
        :class="{
          'single-choice__error-message--hidden': !error
        }"
        :style="{
          color: colors?.error || errorColor
        }"
      >
        {{ errorMessage }}
      </p>
      <p
        v-if="footerMessage"
        class="single-choice__footer-message"
      >
        {{ footerMessage }}
      </p>
      <div
        class="single-choice__buttons"
        :class="[
          {
            'single-choice__buttons--two-columns': twoColumns
          },
          {
            'single-choice__buttons--small': !overrideSmallButton && information && !twoColumns
          }
        ]"
      >
        <signup-button
          v-for="alternative in alternatives"
          :key="alternative.key"
          :color="disabled ? 'grey' : colors?.accent"
          size-large
          :disabled="disabled && !alternative.bypassDisableCheck"
          :data-jest="`button${alternative.key}`"
          :data-cy="`button${alternative.key}`"
          :block="$vuetify.display.smAndDown"
          class="single-choice__button"
          :class="{
            'single-choice__button--single-column':
              alternatives.length !== 2 && alternatives.length < 6
          }"
          :secondary="alternative.secondary"
          @click="buttonClick(alternative.key)"
        >
          <div
            class=""
            :class="[
              'single-choice__button-content',
              {
                'single-choice__button-content--with-icon': alternative.icon || alternative.image
              },
              { 'single-choice__full-width': alternative.hintValue }
            ]"
          >
            {{ alternative.text }}

            <span
              v-if="alternative.hintValue"
              class="single-choice__button-hint"
            >
              {{ alternative.hintValue + " " + alternative.unit }}
            </span>
          </div>
          <template #append>
            <div
              v-if="alternative.image"
              class="single-choice__button-image-wrapper"
            >
              <img
                class="single-choice__button-image-wrapper__image"
                :src="alternative.image"
              />
            </div>
            <div v-if="alternative.icon">
              <icon
                fixed-width
                :icon="alternative.icon"
                class="single-choice__button-icon"
              />
            </div>
          </template>
        </signup-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { SingleChoice } from "@/types/signup";
import SignupButton from "@/components/button.vue";
import { softBlue, nearlyBlack, Colors } from "@/styles/colors";
import { NumberFoundLegacy } from "libphonenumber-js";
import { SignupState } from "@/store/signup/types";

export default defineComponent({
  emits: ["click", "showInformation"],
  components: {
    SignupButton
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    errorColor: {
      type: String,
      default: "#000000"
    },
    twoColumns: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alternatives: {
      type: Array as PropType<SingleChoice[]>,
      default: () => [] as SingleChoice[]
    },
    information: {
      type: Boolean,
      default: false
    },
    informationArrow: {
      type: Boolean,
      default: false
    },
    footerMessage: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: undefined
    },
    allowContentOverflow: {
      type: Boolean,
      default: false
    },
    noTopPadding: {
      type: Boolean,
      default: false
    },
    overrideSmallButton: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String as () => String | undefined,
      required: false
    }
  },
  data: () => ({
    softBlue,
    nearlyBlack
  }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    primaryColor(): string {
      return this.color ? this.color : this.$store.state.signupStore.primaryColor;
    }
  },
  methods: {
    buttonClick(key: string | number) {
      this.$emit("click", key);
    },
    showInformation() {
      this.$emit("showInformation", true);
    }
  }
});
</script>

<style lang="scss" scoped>
.single-choice {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  padding: 0 0 6vh 0;
  height: 100%;
  @include medium-up {
    max-height: $signup-desktop-max-height;
  }

  &--top-padding {
    padding-top: 6vh;
  }
  &__information-button-wrapper {
    position: absolute;
    right: 0;
    bottom: 6vh;
    @include medium-up {
      top: 38.75rem;
    }
    @include xlarge-up {
      top: auto;
      bottom: 6vh;
    }
  }
  &__information-button {
    background-color: $white !important;
    font-size: 1.125rem;
    box-shadow: 0 0.1875rem 0.75rem #00000014;
    border-radius: 0.5rem 0 0 0.5rem;
    transition: background-color 0.1s ease-in-out;
    min-width: 3rem !important;
    &--margin-bottom {
      margin-bottom: 4.25rem;
    }

    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
  &__information-button-icon {
    text-align: center;
    justify-content: center;
    margin-left: 0.1rem;
  }
  &__information-button-arrow {
    position: relative;
    top: -2rem;
    right: -0.4rem;
  }
  &__slot-wrapper {
    position: relative;
    height: 100%;
    &--overflow {
      @include medium-up {
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
  &__button {
    &--single-column {
      @include medium-up {
        grid-column: 1 / 3;
      }
    }
  }
  &__buttons {
    display: grid;
    grid-column-gap: 1.25rem;
    grid-row-gap: 3vh;
    margin: 0 auto;
    max-width: 100%;
    width: 16rem;
    &--small {
      width: 12rem;
    }
    @include medium-up {
      grid-template-columns: repeat(2, 1fr);
      width: auto;
    }
    &--two-columns {
      grid-template-columns: 1fr;
      @include medium-up {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  &__button-content {
    &--with-icon {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  &__button-icon {
    color: #fff;
  }
  &__button-image-wrapper {
    display: flex;
    &__image {
      width: 1.25em;
    }
  }
  &__full-width {
    width: 100%;
    text-align: left;
  }
  &__button-hint {
    float: right;
    font-weight: 300;
    margin-left: 2rem;
  }
  &__footer-message {
    font-size: 0.75rem;
    opacity: 0.63;
    grid-row: 1;
    grid-column: 1/-1;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    display: flex;
    max-width: 44.5rem;
    margin: 0 auto 0 auto;

    &--hidden {
      visibility: hidden;
    }

    @include medium-up {
      font-weight: 300;
      font-size: 0.9rem;
      margin-top: 2.7rem;
    }
    @include large-up {
      margin-top: 0rem;
    }
  }
  &__error-message {
    font-size: 0.75rem;
    margin-bottom: 0;
    grid-row: 1;
    grid-column: 1/-1;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    text-align: center;
    &--hidden {
      visibility: hidden;
    }
  }
  &__footer {
    display: grid;
    grid-template-rows: 1rem auto;
    grid-row-gap: calc(4vh - 1rem);
    margin-top: 2vh;

    @include medium-up {
      grid-template-rows: 1.5rem auto;
    }
  }
}
</style>
