<template>
  <div class="politically-exposed-country">
    <single-choice
      :alternatives="navigationAlternatives"
      :disabled="!(chosenYear && chosenMonth)"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-terminated.heading1')"
        :body="$t('create-portfolio.questions.aml.politically-exposed-terminated.body')"
        progress
      />
      <div class="politically-exposed-country__choices">
        <v-select
          v-model="chosenYear"
          style="width: 16rem"
          :bg-color="softBlue"
          :items="years"
          :label="$t('create-portfolio.questions.aml.politically-exposed-terminated.selectYear')"
          variant="solo"
          menu-icon="fas fa-chevron-down"
          hide-details
        />
        <br />
        <v-select
          v-model="chosenMonth"
          style="width: 16rem"
          :bg-color="softBlue"
          :items="months"
          :label="$t('create-portfolio.questions.aml.politically-exposed-terminated.selectMonth')"
          variant="solo"
          menu-icon="fas fa-chevron-down"
          hide-details
        />
      </div>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import CountrySelector from "@/components/country-selector.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice,
    CountrySelector
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false,
      associated: this.$router.currentRoute.value.path.includes("associated"),
      softBlue
    };
  },
  computed: {
    chosenYear: {
      get(): string | undefined {
        return this.associated
          ? this.$store.state.signupStore.associatedPepInfo.terminatedYear
          : this.$store.state.signupStore.pepInfo.terminatedYear;
      },
      set(value: string) {
        this.associated
          ? this.$store.commit(SignupMutation.setAssociatedPepTerminatedYear, value)
          : this.$store.commit(SignupMutation.setPepTerminatedYear, value);
      }
    },
    chosenMonth: {
      get(): string | undefined {
        return this.associated
          ? this.$store.state.signupStore.associatedPepInfo.terminatedMonth
          : this.$store.state.signupStore.pepInfo.terminatedMonth;
      },
      set(value: string) {
        this.associated
          ? this.$store.commit(SignupMutation.setAssociatedPepTerminatedMonth, value)
          : this.$store.commit(SignupMutation.setPepTerminatedMonth, value);
      }
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    years(): string[] {
      const currentYear = new Date().getFullYear();
      const start = currentYear - 18;
      return Array.from(Array(19).keys())
        .map((_, idx) => (start + idx).toString())
        .reverse();
    },
    months(): string[] {
      return Array.from(Array(12).keys()).map((_, idx) => (1 + idx).toString());
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss">
.politically-exposed-country {
  height: 100%;
  width: 100%;

  &__choices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6vh auto 0 auto;

    @include small-up {
      padding-top: 2rem;
    }

    &--text {
      color: white;
      font-weight: 600;
    }

    &--icon {
      color: white;
    }
  }
}
</style>
