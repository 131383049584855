<template>
  <SideDialog
    v-if="showDialog"
    :model-value="showDialog"
    :title="$t('connectBankAccount')"
    data-jest="dialog"
    :show-back-button="state !== State.Intro && state !== State.Complete"
    @update:model-value="resetAndClose"
    @back="goBack"
  >
    <div style="width: 100%; height: 100%; padding: 2rem">
      <div
        v-if="state == State.Intro"
        class="dialog"
      >
        <div style="font-weight: bold; text-align: center">
          {{ $t("components.connect-bank-side-dialog.title") }}
        </div>
        <div style="text-align: center">
          {{ `${$t("portfolio")}: ${portfolioName}` }}
        </div>
        <div style="text-align: center">
          {{ $t("components.connect-bank-side-dialog.body") }}
        </div>
        <div class="dialog__button-wrapper">
          <CommonButton
            style="justify-self: center; width: 17rem"
            @click="goToTink"
          >
            {{ $t("components.connect-bank-side-dialog.openTink") }}
          </CommonButton>
        </div>
      </div>
      <div
        v-if="state === State.Tink"
        style="width: 100%; height: 100%"
      >
        <iframe
          id="tink-iframe"
          style="width: 100%; height: 100%"
          :src="tinkPath"
        />
      </div>
      <div
        v-if="state === State.ManualAccountEntry"
        class="dialog"
      >
        <div style="font-weight: bold; text-align: center">
          {{ $t("tinkCommunicationError") }}
        </div>
        <div style="text-align: center">
          {{ $t("tinkErrorBody") }}
        </div>
        <div class="account__dropdown">
          <v-select
            v-model="chosenBank"
            :bg-color="softBlue"
            :items="items"
            :label="$t('selectBank')"
            variant="solo"
            menu-icon="fas fa-chevron-down"
            data-cy="bank"
            @update:model-value="setClearingNumberErrorState"
          >
            <template #selection="props">
              <span class="account__dropdown--text">{{ props.item.title }}</span>
            </template>
          </v-select>
        </div>
        <v-form
          ref="form"
          v-model="isFormValid"
          class="account-form"
        >
          <p
            v-if="$vuetify.display.mdAndUp"
            class="account-form__label"
          >
            {{ $t("clearing_number") }}
          </p>
          <v-text-field
            v-model="editedClearingNumber"
            validate-on="blur"
            type="tel"
            variant="underlined"
            :error="clearingNumberErrorState"
            :rules="[rules.required, rules.number]"
            :label="$t('clearing_number')"
            data-cy="clearing-value"
            name="clearingNum"
            class="account-form__value"
            @focus="clearingNumberErrorState = false"
            @blur="setClearingNumberErrorState"
            @update:model-value="validateForm"
          />

          <p
            v-if="$vuetify.display.mdAndUp"
            class="account-form__label"
          >
            {{ $t("accountNumber") }}
          </p>
          <v-text-field
            v-model="editedAccountNumber"
            validate-on="blur"
            type="tel"
            variant="underlined"
            :label="$t('accountNumber')"
            :rules="[rules.required, rules.number, rules.validAccountNumber]"
            data-cy="accountnumber-value"
            name="accountNumber"
            class="account-form__value"
            @focus="clearingNumberErrorState = false"
            @blur="setClearingNumberErrorState"
            @update:model-value="validateForm"
          />
          <input
            tabindex="-1"
            class="account-form__value--hidden"
            type="submit"
            value=""
          >
        </v-form>
        <div class="dialog__button-wrapper">
          <CommonButton
            :disabled="!isFormValid"
            style="justify-self: center; width: 17rem"
            @click="goToAccountSummary"
          >
            {{ $t("continue") }}
          </CommonButton>
        </div>
      </div>
      <div
        v-if="state === State.AccountSummary"
        class="dialog"
      >
        <div v-if="loadingAccountDetails || preparingDocuments">
          <div style="font-weight: bold; text-align: center">
            {{ $t("verifyingBankAccount") }}
          </div>
          <LoadingSpinner />
        </div>
        <div
          v-else
          class="dialog__wrapper"
        >
          <div style="font-weight: bold; text-align: center">
            {{ $t("bankAccountRetrieved") }}
          </div>
          <div style="margin-top: 1rem; text-align: center">
            {{ $t("tinkAccountExplanation") }}
          </div>
          <div class="dialog__wrapper-safari-fix">
            <div class="dialog__grid">
              <p class="dialog__wrapper-label">
                {{ $t("bank") }}
              </p>
              <p class="dialog__wrapper-value">
                {{ bankName }}
              </p>
              <p class="dialog__wrapper-label">
                {{ $t("accountNumber") }}
              </p>
              <div class="dialog__account-wrapper">
                <p class="dialog__wrapper-value">
                  {{ accountName }}
                </p>
                <span class="dialog__wrapper-hinttext">
                  {{ fullAccountNumber }}
                </span>
              </div>
            </div>
          </div>
          <AgreementsLink
            :text="$t('components.connect-bank-side-dialog.autogiroAgreement')"
            @click="downloadAutogiroAgreement"
          />
          <div class="dialog__button-wrapper">
            <CommonButton
              style="justify-self: center; width: 17rem"
              @click="goToSigning"
            >
              {{ $t("signBankId") }}
            </CommonButton>
          </div>
        </div>
      </div>
      <div v-if="state === State.BankId">
        <BankId
          :sign-location="SignLocation.PORTFOLIO_SETTINGS"
          @complete="signComplete"
          @canceled="signCancel"
          @failed="signFailed"
        />
      </div>
      <div
        v-if="state === State.Complete"
        class="dialog"
      >
        <div v-if="updateError">
          Misslyckades med uppdateringen :( försök igen
        </div>
        <div
          v-else
          style="font-weight: bold; text-align: center"
        >
          {{ $t("components.connect-bank-side-dialog.bankAccountConnectedTitle") }}
        </div>
        <div style="text-align: center">
          {{ $t("components.connect-bank-side-dialog.bankAccountConnectedBody") }}
        </div>
        <div class="dialog__button-wrapper">
          <CommonButton
            style="justify-self: center; width: 17rem"
            @click="resetAndClose"
          >
            {{ $t("done") }}
          </CommonButton>
        </div>
      </div>
    </div>
  </SideDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";
import type { SeverityLevel } from "@sentry/types";
import Kontonummer from "kontonummer";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { PortfolioAction } from "@/store/portfolio/actions";
import type { Bank, Savings, TinkAccount } from "@/types/portfolio";
import BankId from "@/components/bank-id.vue";
import AgreementsLink from "@/components/agreements-link.vue";
import { SignLocation } from "@/store/bankid/types";
import { BankIdAction } from "@/store/bankid/actions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { UserAction } from "@/store/user/action-definitions";

import type { BankOptions } from "@/types/signup";
import { banksWithTransactionAccounts } from "@/types/signup";
import type { InfoSection } from "@/types";
import { softBlue } from "@/styles/colors";
import { PdfType } from "@/clients";
import type { PortfolioState } from "@/store/portfolio/types";

interface VSelectItem {
  title: string;
  value: string;
}

enum State {
  Intro,
  Tink,
  ManualAccountEntry,
  AccountSummary,
  BankId,
  Complete,
}

export default defineComponent({
  components: {
    SideDialog,
    CommonButton,
    BankId,
    LoadingSpinner,
    AgreementsLink,
  },
  data() {
    return {
      SignLocation,
      State,
      state: State.Intro,
      loadingAccountDetails: false,
      tinkPath: "",
      callbackFunc: null as any,
      tinkAccount: undefined as TinkAccount | undefined,
      preparingDocuments: false,
      updateError: false,
      order: [State.Intro, State.Tink, State.AccountSummary, State.BankId, State.Complete],
      chosenBank: undefined as string | undefined,
      editedClearingNumber: undefined as string | undefined,
      editedAccountNumber: undefined as string | undefined,
      clearingNumberErrorState: false,
      isFormValid: false,
      softBlue,
    };
  },
  computed: {
    ...mapGetters(["isCompany", "portfolioName", "isTestUser"]),
    infoSections(): InfoSection[] {
      return [
        {
          heading: this.$t("infoTransactionTitle"),
          text: this.$t("infoTransactionText"),
        },
        {
          heading: this.$t("accountNumber"),
          text: this.$t("infoAccountNumberText1"),
        },
        {
          text: this.$t("infoAccountNumberText2"),
        },
      ];
    },
    items(): VSelectItem[] {
      return banksWithTransactionAccounts.map((x: BankOptions) => ({
        title: x,
        value: x,
        key: x,
      }));
    },
    rules(): any {
      return {
        required: (value: string) => !!value || this.$t("required"),
        number: (value: string) => {
          value = value ? value.replace(/\s/g, "") : "";
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("not_valid_number");
        },
        validAccountNumber: () => {
          const valid
            = this.bankFromAccountNumber
            && this.bankFromAccountNumber.valid
            && this.chosenBank === this.bankFromAccountNumber.bankName;
          return valid || this.$t("does_not_match_bank");
        },
      };
    },
    bankName(): string | undefined {
      if (this.tinkAccount) {
        return this.tinkAccount.bank;
      }
      if (this.chosenBank) {
        return this.chosenBank;
      }
      return "-";
    },
    accountName(): string | undefined {
      if (this.tinkAccount) {
        return this.tinkAccount.accountName;
      }
      return this.$t("accountNamePlaceholder");
    },
    clearingNumber(): string | undefined {
      if (this.tinkAccount) {
        return this.tinkAccount.clearingNumber;
      }
      if (this.editedClearingNumber) {
        return this.editedClearingNumber;
      }
      return "-";
    },
    accountNumber(): string | undefined {
      if (this.tinkAccount) {
        return this.tinkAccount.accountNumber;
      }
      if (this.editedAccountNumber) {
        return this.editedAccountNumber;
      }
      return "-";
    },
    fullAccountNumber(): string | undefined {
      if (this.tinkAccount) {
        return this.tinkAccount.fullAccountNumber;
      }
      if (this.editedClearingNumber && this.editedAccountNumber) {
        return `${this.editedClearingNumber}, ${this.editedAccountNumber}`;
      }
      return "-";
    },
    showDialog: {
      set(value: boolean) {
        this.$store.commit(PortfolioMutation.setShowConnectBankSideDialog, value);
      },
      get(): boolean {
        return this.$store.state.portfolioStore.showConnectBankSideDialog;
      },
    },
    bankFromAccountNumber(): Bank | undefined {
      const strippedClearingNumber = this.removeWhiteSpace(this.editedClearingNumber);
      const strippedAccountNumber = this.removeWhiteSpace(this.editedAccountNumber);
      let bank: Kontonummer | undefined;
      try {
        bank = new Kontonummer(strippedClearingNumber, strippedAccountNumber, {
          mode: "lax",
        });
      } catch (error: any) {
        // Throws despite setting mode: lax if user hasn't finished typing yet. Just ignore this.
      }
      if (bank) {
        return {
          bankName: bank.bankName as string,
          accountNumber: bank.accountNumber,
          clearingNumber: bank.sortingCode,
          valid: bank.valid,
        };
      }
      return undefined;
    },
  },
  methods: {
    async setClearingNumberErrorState() {
      if (this.$refs.form && this.editedClearingNumber && this.editedAccountNumber) {
        this.clearingNumberErrorState = !(await (this.$refs.form as any).validate()).valid;
      }
    },
    resetAndClose(): void {
      this.showDialog = false;
      this.state = State.Intro;
      this.loadingAccountDetails = false;
      this.tinkPath = "";
      this.callbackFunc = null as any;
      this.tinkAccount = undefined as TinkAccount | undefined;
    },
    async goToSigning(): Promise<void> {
      this.state = State.BankId;
      try {
        await this.$store.dispatch(BankIdAction.sign);
      } catch (e) {
        console.error("signing error", e);
        throw e;
      }
    },
    cancel(): void {
      this.$store.dispatch(BankIdAction.cancelSign);
    },
    async signComplete(): Promise<void> {
      try {
        await this.$store.dispatch(PortfolioAction.completeUpdatePortfolioSettings);
        this.state = State.Complete;
      } catch (error: any) {
        console.error("Failed to update settings", error);
        this.updateError = true;
        this.state = State.BankId;
        this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("updateFailed"));
      }
    },
    signCancel(): void {
      this.state = State.AccountSummary;
    },
    signFailed(): void {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signFailed"));
      this.state = State.BankId;
    },
    goToTink(): void {
      this.state = State.Tink;
      this.openTink();
    },
    goBack(): void {
      const currentIndex = this.order.indexOf(this.state);
      if (currentIndex !== 0) {
        this.state = this.order[currentIndex - 1];
      }
    },
    async goToAccountSummary(): Promise<void> {
      if (this.state === State.AccountSummary) {
        return;
      }
      this.state = State.AccountSummary;
      const savings: Savings = {
        monthlySaving: this.$store.state.portfolioStore.portfolioSettings.monthlySaving as number,
        accountNumber: this.accountNumber as string,
        clearingNumber: this.clearingNumber as string,
        bankName: this.bankName as string,
      };
      this.$store.commit(PortfolioMutation.setEditedSavings, savings);
      this.preparingDocuments = true;
      await this.$store.dispatch(PortfolioAction.prepareUpdatePortfolioSettings);
      this.preparingDocuments = false;
    },
    goToFallbackQuestion() {
      this.state = State.ManualAccountEntry;
      this.loadingAccountDetails = false;
    },
    async openTink(): Promise<void> {
      this.tinkAccount = undefined;
      const webUrl = encodeURIComponent(import.meta.env.VITE_WEB_BASE_URL as string);

      this.tinkPath
        = import.meta.env.VITE_ENV === "production"
          ? `https://link.tink.com/1.0/account-check?client_id=eab901e187df45c3b470145117eaf4de&redirect_uri=${webUrl}&market=SE&locale=sv_SE&iframe=true&refreshable_items=CHECKING_ACCOUNTS`
          : `https://link.tink.com/1.0/account-check?client_id=b55f3076cf2c4e26a6b655b6b23217bc&redirect_uri=${webUrl}&market=SE&locale=sv_SE&test=true&input_provider=se-test-bankid-successful&input_username=180012126444&iframe=true&refreshable_items=CHECKING_ACCOUNTS`;

      this.callbackFunc = async (event: any) => {
        if (event.origin !== "https://link.tink.com") {
          return;
        }

        // Read more about the message format here: https://docs.tink.com/api/#tink-link-response-iframe-mode
        const { type, data, error } = JSON.parse(event.data);

        if (type === "account_verification_report_id") {
          this.loadingAccountDetails = true;
          try {
            this.tinkAccount = await this.$store.dispatch(
              PortfolioAction.getAccountDetailsPortfolio,
              data,
            );
            this.goToAccountSummary();
          } catch (error: any) {
            this.state = State.ManualAccountEntry;
          }

          this.loadingAccountDetails = false;
        } else if (type === "error") {
          // Handle error response from Tink Link
          if (error.status === "USER_CANCELLED") {
            // Hacky to just reload iframe
            (document as any).getElementById("tink-iframe").src += "";
          } else {
            console.error(
              `Tink returned with error status: ${error?.status} and error message: ${error?.message}.`,
            );
            Sentry.captureMessage(
              `Tink error: ${error?.status}, ${error?.message}`,
              "error" as SeverityLevel,
            );
            this.goToFallbackQuestion();
          }
        } else if (type === "credentials") {
          // Handle credentials error response from Tink Link
          const credentialsId = data;

          console.error(
            `Tink authentication failed with credentials identifier: ${credentialsId} with error status: ${error?.status} and error message: ${error?.message}.`,
          );
          Sentry.captureMessage(
            `Tink credentials error: ${error?.status}, ${error?.message}`,
            "error" as SeverityLevel,
          );
          this.goToFallbackQuestion();
        } else if (data.screen === "PROVIDER_TEMPORARY_DISABLED_SCREEN") {
          this.goToFallbackQuestion();
        }
      };
      window.addEventListener("message", this.callbackFunc);
    },
    removeWhiteSpace(value: string | undefined): string {
      if (!value) {
        return "";
      }
      return value.replace(/\s/g, "");
    },
    async validateForm(): Promise<boolean> {
      if (this.$refs.form && this.editedClearingNumber && this.editedAccountNumber) {
        return (await (this.$refs.form as any).validate()).valid;
      }
      return false;
    },
    downloadAutogiroAgreement(): void {
      this.$store.dispatch(UserAction.getPreviewDocument, {
        pdfType: PdfType.Autogiro,
        portfolioExternalReference: (this.$store.state.portfolioStore as PortfolioState)
          .activePortfolioBase?.externalReference,
        pdfId: 0,
      });
    },
  },
});
</script>

<style
  lang="scss"
  scoped
>
.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 1rem;
  height: 100%;

  &__subheader {
    font-size: 1.125rem;
    text-align: center;
    font-family: $heading-font-family;
  }

  &__description {
    font-size: 0.875rem;
    opacity: 0.8;
  }

  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__wrapper-safari-fix {
    // Fixes grid row height overflowing in safari (https://stackoverflow.com/a/63197330/2152511)
    display: grid;
    align-items: center;
    justify-items: center;
  }

  &__grid {
    max-width: 21.75rem;
    margin: 2vh auto 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1.25rem;
  }

  &__wrapper-label {
    margin: 0;
    text-align: left;
  }

  &__wrapper-value {
    margin: 0;
    font-family: $heading-font-family;
    text-align: right;
  }

  &__wrapper-hinttext {
    opacity: 0.63;
    text-align: right;
    margin: 0;
    white-space: nowrap;
  }

  &__wrapper-error {
    margin-top: 2rem;
    color: red;
  }

  &__account-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.account {
  width: 100%;
  height: 100%;

  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 18.75rem;
    width: 100%;
    margin: 6vh auto 0 auto;

    &--text {
      color: white;
      font-weight: 600;
    }

    &--icon {
      color: white;
    }
  }

  &__side-dialog {
    padding: 1rem;

    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }

    &--text {
      font-weight: 300;
    }
  }
}

.account-form {
  align-items: center;
  max-width: 31.25rem;
  margin: 0 auto 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-rows: 5rem 5rem;
  width: 100%;

  &__bank {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
  }

  @include medium-up {
    grid-template-columns: 1fr 10rem 10rem;

    :nth-child(1) {
      grid-row: 1;
    }

    :nth-child(3) {
      grid-row: 2;
    }

    :nth-child(4) {
      grid-row: 2;
      grid-column: 2 / -1;
    }

    :nth-child(5) {
      grid-row: 3;
    }

    :nth-child(6) {
      grid-row: 3;
      grid-column: 2 / -1;
    }
  }

  &__label {
    margin: 0;
    text-align: right;
    font-size: 1.25rem;
  }

  &__value {
    margin: 0;
    font-family: $heading-font-family;

    @include medium-up {
      width: 13rem;
    }

    &--hidden {
      display: none;
    }

    :deep(input) {
      font-size: 1.5rem;
    }
  }
}
</style>
